import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loading';
import getUploads from '../../helpers/getUploads';
import { useLang } from '../../helpers/language';

export default function Tag() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.tag;
  const { lang } = useLang();
  const [index, setIndex] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [nameRu, setNameRu] = useState('');
  const [nameHy, setNameHy] = useState('');
  const [color, setColor] = useState('');
  // eslint-disable-next-line
  const [image, setImage] = useState(undefined);

  const modelSendToServer = {
    index,
    name: {
      en: nameEn,
      ru: nameRu,
      hy: nameHy,
    },
    color,
  };

  useEffect(() => {
    (async () => {
      if (id !== 'add') {
        let currentItem = await API.get(route, {
          _id: id,
        });
        setIndex(currentItem[0]?.index);
        setNameEn(currentItem[0]?.name?.en);
        setNameRu(currentItem[0]?.name?.ru);
        setNameHy(currentItem[0]?.name?.hy);
        setColor(currentItem[0]?.color);

        let images = await getUploads(id, 'image');
        setImage(images && images[0]);
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: index,
            setter: setIndex,
            type: 'number',
            usageType: 'number',
            label: config.translate.index[lang],
            placeholder: config.translate.index[lang],
          },
          {
            value: nameHy,
            setter: setNameHy,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.name[lang],
            placeholder: 'Մուտքագրել հայատառ',
            lang: 'hy',
          },
          {
            value: nameRu,
            setter: setNameRu,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.name[lang],
            placeholder: 'Մուտքագրել հայատառ',
            lang: 'ru',
          },
          {
            value: nameEn,
            setter: setNameEn,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.name[lang],
            placeholder: 'Մուտքագրել հայատառ',
            lang: 'en',
          },
          {
            value: color,
            setter: setColor,
            type: 'text',
            usageType: 'color-picker',
            label: config.translate.color[lang],
            placeholder: config.translate.color[lang],
          },
        ]}
      />
    );
}
