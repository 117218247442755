import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useConnectedUser } from '../../helpers/connectedUserContext';
import { Link } from 'react-router-dom';
import config from '../../config';

export default function Navbar(props) {
  const [notificationOpened, setnotificationOpened] = useState(false);
  const { socket, notifications, setnotifications } = useConnectedUser();
  let audio = useRef(null);
  const lang = 'hy';
  function toggleFullScreen() {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  }

  function toggleNotification(e, i) {
    setnotificationOpened(!notificationOpened);

    if (i || i === 0) {
      let tempNotifications = [...notifications];
      tempNotifications.splice(i, 1);
      setnotifications([...tempNotifications]);
      socket.emit('updateOrders', [...tempNotifications]);
    }
  }

  function clearNotifications() {
    setnotifications([]);
    socket.emit('clearOrders', []);
    toggleNotification();
  }

  useEffect(() => {
    audio?.current?.play();
  }, [notifications]);

  return (
    <nav className='main-header navbar navbar-expand navbar-white navbar-light'>
      <ul className='navbar-nav'>
        <li className='nav-item'>
          <span
            className='nav-link'
            data-widget='pushmenu'
            onClick={props.settings.toggleSidebar}
            role='button'>
            <i className='fas fa-bars'></i>
          </span>
        </li>
      </ul>
      <ul className='navbar-nav ml-auto'>
        {
          <li className='nav-item dropdown'>
            <button className='nav-link' onClick={toggleNotification}>
              <i className='far fa-bell'></i>
              {notifications?.length > 0 && (
                <span className='badge badge-warning navbar-badge'>
                  {notifications?.length}
                </span>
              )}
            </button>
            <audio
              ref={audio}
              src='/assets/beep.mp3'
              defaultMuted='true'></audio>
            <div
              className={`dropdown-menu dropdown-menu-lg dropdown-menu-right ${
                notificationOpened ? 'show' : ''
              }`}
              // style="left: inherit; right: 0px;"
            >
              <span className='dropdown-item dropdown-header'>
                {notifications?.length +
                  ' ' +
                  config.translate.notifications[lang]}
              </span>
              <div className='dropdown-divider'></div>
              {notifications?.map((not, i) => {
                return (
                  <Link
                    to={`/order/${not?._id}`}
                    className='dropdown-item'
                    key={i}
                    onClick={(e) => {
                      toggleNotification(e, i);
                    }}>
                    <i className='fas fa-envelope mr-2'></i>
                    {not?.user} {config.translate.create[lang]} №{not?.ID}
                    <span className='float-right text-muted text-sm'>
                      {moment().diff(not?.date, 'm')}{' '}
                      {config.translate.minute[lang]}
                    </span>
                  </Link>
                );
              })}
              <div className='dropdown-divider'></div>
              <button
                className='dropdown-item dropdown-footer'
                onClick={clearNotifications}>
                {config.translate.reset[lang] +
                  ' ' +
                  config.translate.notifications[lang]}{' '}
                <i className='fa fa-trash'></i>
              </button>
            </div>
          </li>
        }
        <li className='nav-item'>
          <button className='nav-link' onClick={toggleFullScreen}>
            <i className='fas fa-expand-arrows-alt'></i>
          </button>
        </li>
      </ul>
    </nav>
  );
}
