import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loading';
import getUploads from '../../helpers/getUploads';
import { useUser } from '../../helpers/userContext';
import { useLang } from '../../helpers/language';

export default function Category() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.category;
  const { lang } = useLang();
  const { user } = useUser();

  const [index, setIndex] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [nameRu, setNameRu] = useState('');
  const [nameHy, setNameHy] = useState('');
  const [href, setHref] = useState('');

  const [parentCategory, setParentCategory] = useState(null);
  const [parentCategories, setParentCategories] = useState('');

  const [market, setMarket] = useState('');
  const [markets, setMarkets] = useState('');

  const [image, setImage] = useState('');

  const modelSendToServer = {
    index,
    name: {
      en: nameEn,
      ru: nameRu,
      hy: nameHy,
    },
    href,
    parentCategory_id: parentCategory ? parentCategory : undefined,
    market_id: market ? market : undefined,
  };

  useEffect(() => {
    (async () => {
      let rawParentCategories = await API.get(config.api.category);
      if (rawParentCategories) setParentCategories(rawParentCategories);

      let rawMarkets = await API.get(config.api.market);
      if (rawMarkets) setMarkets(rawMarkets);

      if (id !== 'add') {
        let currentItem = await API.get(route, {
          _id: id,
        });
        setIndex(currentItem[0]?.index);
        setNameEn(currentItem[0]?.name?.en);
        setNameRu(currentItem[0]?.name?.ru);
        setNameHy(currentItem[0]?.name?.hy);
        setHref(currentItem[0]?.href);
        setParentCategory(currentItem[0]?.parentCategory_id);
        setMarket(currentItem[0]?.market_id);

        let images = await getUploads(id, 'image');
        setImage(images && images[0]);

        parentCategories &&
          parentCategories.forEach((item) => {
            if (item._id === currentItem[0]?.parentCategory_id) {
              setParentCategory(item._id);
            }
          });

        markets &&
          markets.forEach((item) => {
            if (item._id === currentItem[0]?.market_id) {
              setMarket(item._id);
            }
          });
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        userRoles={['admin', 'editor']}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: index,
            setter: setIndex,
            type: 'number',
            usageType: 'number',
            label: config.translate.index[lang],
            placeholder: 'Index',
          },
          {
            value: href,
            setter: setHref,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.href[lang],
            placeholder: config.translate.href[lang],
            required: true,
          },
          {
            value: nameHy,
            setter: setNameHy,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: 'hy',
          },
          {
            value: nameRu,
            setter: setNameRu,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: 'ru',
          },
          {
            value: nameEn,
            setter: setNameEn,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: 'en',
          },
          {
            value: parentCategory,
            setter: setParentCategory,
            optionListValue: parentCategories,
            type: 'optionlist',
            usageType: 'optionlist',
            label: 'Parent Categories',
          },
          {
            value: market,
            setter: setMarket,
            optionListValue: markets,
            type: 'optionlist',
            usageType: 'optionlist',
            label: 'Markets',
          },
        ]}
        filesComponent={[
          {
            destiny: 'image',
            file: image,
            filesUploadLimit: 1,
            adminMode: user.role !== 'editor',
            acceptedFiles: '.jpg, .jpeg, .png',
            thumbSizeWidth: 100,
            thumbSizeHeight: 100,
            responsiveImages: true,
          },
        ]}
      />
    );
}
