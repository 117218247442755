import React, { useState } from 'react';
import config from '../../config';
import Input from './input';
import { TableComponent } from './tableComponent';
import { useLang } from '../../helpers/language';
import Select from 'react-select';

export function CartComponent(props) {
  const [data, setData] = useState(props.data || undefined);
  const { lang } = useLang();
  const [quantity, setQuantity] = useState(0);
  const options =
    props?.value?.length > 0 &&
    props?.value?.map((item) => {
      return { label: item.name.hy, value: item._id };
    });
  function onSelectChange(val) {
    setData(val.value);
  }

  
  function addToList() {
    let temporaryCart = props.data.length > 0 ? [...props?.data] : [];
    let selected;
    props.value.forEach((item) => {
      if (item._id === data) {
        selected = { ...item };
      }
    });
    selected.quantity = +quantity;

    if (temporaryCart.find((item) => item._id === selected._id)) {
      temporaryCart = temporaryCart.map((item) => {
        if (item._id === selected._id) {
          return {
            ...item,
            quantity: item.quantity + selected.quantity,
          };
        } else {
          return item;
        }
      });
    } else {
      temporaryCart.push(selected);
    }

    // if (selected) temporaryCart.push(selected);
    props.setter(temporaryCart);
    setQuantity(0);
    setData(undefined);
  }

  return (
    <div className='cart-element'>
      <label htmlFor=''>{props.title}</label>
      <div className='btn-group'>
        <Select
          name='form-field-name'
          options={options}
          onChange={onSelectChange}
          className={'form-element form-element-wide'}
        />
        <Input
          usageType='number'
          type='number'
          value={quantity}
          setter={setQuantity}
          width='100px'
          min='1'
        />

        <button
          className={`btn ${
            data && data.length > 0 ? 'btn-primary' : 'btn-disabled'
          }`}
          onClick={addToList}
          disabled={quantity && quantity > 0 && data.length > 0 ? false : true}
        >
          <i className='fas fa-download'></i>
          <span style={{ marginLeft: 10 }}>
            {config.translate.addToList[lang]}
          </span>
        </button>
      </div>

      <TableComponent
        data={props.data}
        columns={props.columns}
        setter={props.setter}
        conditionalRowStyles={props.conditionalRowStyles}
        pagination={props.pagination}
        customStyles={props.customStyles}
      />
    </div>
  );
}
