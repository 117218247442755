import React from 'react';
import config from '../../config';
import { useLang } from '../../helpers/language';

export default function Footer(props) {
  const { lang } = useLang();

  return (
    <footer className='main-footer'>
      <strong>
        {config.translate.developed[lang]}{' '}
        <a
          href='https://www.deepmindsystems.com'
          rel='noreferrer'
          target='_blank'
        >
          Deepmindsystems
        </a>
      </strong>
      <div className='float-right d-none d-sm-inline-block'>
        <b>{config.translate.version[lang]}</b> {config.api.API_Version}
      </div>
    </footer>
  );
}
