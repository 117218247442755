import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loading';
import getUploads from '../../helpers/getUploads';
import { useUser } from '../../helpers/userContext';
import { useLang } from '../../helpers/language';

export default function User() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.user;
  const { lang } = useLang();

  const [index, setIndex] = useState('');
  const [firstNameEn, setFirstNameEn] = useState('');
  const [firstNameRu, setFirstNameRu] = useState('');
  const [firstNameHy, setFirstNameHy] = useState('');
  const [lastNameEn, setLastNameEn] = useState('');
  const [lastNameRu, setLastNameRu] = useState('');
  const [lastNameHy, setLastNameHy] = useState('');
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');

  const { user } = useUser();
  const [image, setImage] = useState(undefined);

  const modelSendToServer = {
    index,
    firstName: {
      en: firstNameEn,
      ru: firstNameRu,
      hy: firstNameHy,
    },
    lastName: {
      en: lastNameEn,
      ru: lastNameRu,
      hy: lastNameHy,
    },
    username,
    password,
    dateOfBirth,
    role,
    email,
  };

  useEffect(() => {
    (async () => {
      if (id !== 'add') {
        let currentItem = await API.get(route, {
          _id: id,
        });
        setIndex(currentItem[0]?.index);
        setFirstNameEn(currentItem[0]?.firstName?.en);
        setFirstNameRu(currentItem[0]?.firstName?.ru);
        setFirstNameHy(currentItem[0]?.firstName?.hy);
        setLastNameEn(currentItem[0]?.lastName?.en);
        setLastNameRu(currentItem[0]?.lastName?.ru);
        setLastNameHy(currentItem[0]?.lastName?.hy);
        setUserName(currentItem[0]?.username);
        setPassword(currentItem[0]?.password);
        currentItem[0]?.dateOfBirth &&
          setDateOfBirth(currentItem[0]?.dateOfBirth);
        setRole(currentItem[0]?.role);
        setEmail(currentItem[0]?.email);

        let images = await getUploads(id, 'image');
        setImage(images && images[0]);

        config.translate.roles.forEach((item) => {
          if (item._id === currentItem[0]?.role) {
            setRole(item._id);
          }
        });
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        modelSendToServer={modelSendToServer}
        inputs={[
          user.role === 'admin'
            ? {
                value: index,
                setter: setIndex,
                type: 'number',
                usageType: 'number',
                label: config.translate.index[lang],
                placeholder: 'Index',
              }
            : {
                value: index,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.index[lang],
                disabled: true,
              },
          user.role === 'admin'
            ? {
                value: username,
                setter: setUserName,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.username[lang],
                placeholder: 'Input in latin',
              }
            : {
                value: username,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.username[lang],
                disabled: true,
              },
          user.role === 'admin'
            ? {
                value: password,
                setter: setPassword,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.password[lang],
                placeholder: 'Input in latin',
              }
            : user.role !== 'editor'
            ? {
                value: password,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.password[lang],
                disabled: true,
              }
            : null,

          user.role !== 'editor'
            ? {
                value: firstNameHy,
                setter: setFirstNameHy,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.firstName[lang],
                placeholder: config.translate.placeholder[lang],
                lang: 'hy',
              }
            : {
                value: firstNameHy,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.firstName[lang],
                disabled: true,
                lang: 'hy',
              },
          user.role !== 'editor'
            ? {
                value: lastNameHy,
                setter: setLastNameHy,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.lastName[lang],
                placeholder: config.translate.placeholder[lang],
                lang: 'hy',
              }
            : {
                value: lastNameHy,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.lastName[lang],
                disabled: true,
                lang: 'hy',
              },
          user.role !== 'editor'
            ? {
                value: firstNameRu,
                setter: setFirstNameRu,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.firstName[lang],
                placeholder: config.translate.placeholder[lang],
                lang: 'ru',
              }
            : {
                value: firstNameRu,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.firstName[lang],
                disabled: true,
                lang: 'ru',
              },
          user.role !== 'editor'
            ? {
                value: lastNameRu,
                setter: setLastNameRu,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.lastName[lang],
                placeholder: config.translate.placeholder[lang],
                lang: 'ru',
              }
            : {
                value: lastNameRu,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.lastName[lang],
                disabled: true,
                lang: 'ru',
              },
          user.role !== 'editor'
            ? {
                value: firstNameEn,
                setter: setFirstNameEn,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.firstName[lang],
                placeholder: config.translate.placeholder[lang],
                lang: 'en',
              }
            : {
                value: firstNameEn,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.firstName[lang],
                disabled: true,
                lang: 'en',
              },
          user.role !== 'editor'
            ? {
                value: lastNameEn,
                setter: setLastNameEn,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.lastName[lang],
                placeholder: config.translate.placeholder[lang],
                lang: 'en',
              }
            : {
                value: lastNameEn,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.lastName[lang],
                disabled: true,
                lang: 'en',
              },
          user.role === 'admin'
            ? {
                value: role,
                setter: setRole,
                optionListValue: config.translate.roles,
                type: 'optionlist',
                usageType: 'optionlist',
                label: config.translate.role[lang],
              }
            : {
                value: role,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.role[lang],
                disabled: true,
              },
          user.role !== 'editor'
            ? {
                value: dateOfBirth,
                setter: setDateOfBirth,
                type: 'date',
                usageType: 'datePicker',
                label: config.translate.dateOfBirth[lang],
                placeholder: config.translate.dateOfBirth[lang],
              }
            : {
                value: dateOfBirth,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.dateOfBirth[lang],
                disabled: true,
              },
          user.role !== 'editor'
            ? {
                value: email,
                setter: setEmail,
                values: config.translate.genders,
                type: 'text',
                usageType: 'optionlist',
                label: config.translate.gender[lang],
              }
            : {
                value: email,
                type: 'text',
                usageType: 'textfield',
                label: config.translate.gender[lang],
                disabled: true,
              },
        ]}
        filesComponent={[
          {
            destiny: 'image',
            file: image,
            filesUploadLimit: 1,
            acceptedFiles: ['image/*'],
            thumbSizeHeight: 50,
            thumbSizeWidth: 50,
            adminMode: user.role !== 'editor',
          },
        ]}
      />
    );
}
