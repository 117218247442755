import React from "react";
import config from "../config";

// const Pagination = ({ dataPerPage, totalCount, paginate, currentPage }) => {
//   const pageNumbers = [];

//   for (let i = 1; i <= Math.ceil(totalCount / dataPerPage); i++) {
//     pageNumbers.push(i);
//   }

//   return (
//     <div className="wrapper-pagination">
//       {/* <button className="page-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" /></svg></button> */}
//       <ul
//         className={"pagination-month pagination-sm flex-center"}
//       >
//         {pageNumbers.map((number) => {
//           return (
//             <li
//               key={number}
//               className={
//                 currentPage === number
//                   ? `${"active"} ${"page-item"}`
//                   : "page-item"
//               }
//             >
//               <span onClick={() => paginate(number)} className={"page-link"}>
//                 {number}
//               </span>
//             </li>
//           );
//         })}

//       </ul>
//       {/* <button className="page-item"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" /></svg></button> */}
//     </div>
//   );
// };
const Pagination = ({ dataPerPage, totalCount, paginate, currentPage, indexOfFirstPost, indexOfLastPost }) => {
  const pageNumbers = []
  

  for (let i = currentPage - 1; i < currentPage + dataPerPage; i++) {
    if (i <= totalCount && i > 0) {

      pageNumbers.push(i)
    }
  }
  

  return (
    <div className="wrapper-pagination">
      <button className="page-item" onClick={() => paginate(1)}>առաջին</button>
      <ul
        className={"pagination-month pagination pagination-sm flex-center"}
      >
        {pageNumbers.map((number) => {
          return (
            <li
              key={number}
              className={
                currentPage === number
                  ? `${"active"} ${"page-item"}`
                  : "page-item"
              }
              style={{ cursor: 'pointer' }}
            >
              <span onClick={() => paginate(number)} className={"page-link"}>
                {number}
              </span>
            </li>
          );
        })}

      </ul>
      <button className="page-item" onClick={() => paginate(totalCount)}>վերջին</button>
    </div>
  );
};

export default Pagination;
