import React from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import { useLang } from "../../helpers/language";

export default function Products() {
  const { lang } = useLang();
  let customSort = "index";

  const columns = [
    {
      dataField: "index",
      text: config.translate.index[lang],
      filter: true,
      sort: true,
      headerStyle: (column, colIndex) => {
        return {
          width: "110px",
        };
      },
      align: "center",
    },
    {
      dataField: "category_ids",
      text: config.translate.category[lang],
      filter: true,
      sort: true,
      formatLive: true,
    },
    {
      dataField: "code",
      text: config.translate.code[lang],
      filter: true,
      sort: true,
      formatLive: true,
    },
    {
      dataField: "name.hy",
      text: config.translate.name[lang],
      filter: true,
      sort: true,
      formatLive: true,
    },
    {
      dataField: "name.ru",
      text: config.translate.name[lang] + "-Ru",
      filter: true,
      sort: true,
      formatLive: true,
    },
    {
      dataField: "notAvailable",
      text: config.translate.notAvailable[lang],
      filter: true,
      sort: true,
      formatLive: true,
    },
    {
      dataField: "price.basic",
      text: config.translate.price[lang],
      filter: true,
      sort: true,
    },
  ];

  return (
    <ListPage
      customSort={customSort}
      columns={columns}
      isCategoriesNeed={true}
      isNotAvailableNeed={true}
      route={config.api.product}
      defaultSortedDataField={"index"}
    />
  );
}
