import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams, useHistory } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loading';
import { useLang } from '../../helpers/language';

export default function Banner() {
  const history = useHistory();
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.banner;
  const { lang } = useLang();

  const [index, setIndex] = useState('');

  const [nameEn, setNameEn] = useState('');
  const [nameRu, setNameRu] = useState('');
  const [nameHy, setNameHy] = useState('');
  const [file, setfile] = useState(undefined);

  const modelSendToServer = {
    index,
    name: {
      en: nameEn,
      ru: nameRu,
      hy: nameHy,
    },
  };

  useEffect(() => {
    (async () => {
      if (id !== 'add') {
        let currentItem = await API.get(route, {
          _id: id,
        });
        setIndex(currentItem[0]?.index);
        setNameEn(currentItem[0]?.name?.en);
        setNameRu(currentItem[0]?.name?.ru);
        setNameHy(currentItem[0]?.name?.hy);

        let files;
        if (id) {
          files = await API.get(config.api.uploads, {
            obj_id: id,
            type: { $regex: '.*image.*' },
          });
          setfile(files);
        }
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy, history.location.pathname]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: nameHy,
            setter: setNameHy,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: 'hy',
          },
        ]}
        sliderComponent={[
          {
            name: 'images',
            files: file,
            filesUploadLimit: 10,
            buttonAddText: 'Ավելացնել ֆայլեր',
            buttonSaveText: 'Պահպանել',
            buttonCloseText: 'Փակել',
            buttonRemoveText: 'Ջնջել',
            dropzoneText:
              'Բացակայում են ֆայլերը, սեղմեք այստեղ՝ պատկերներ ավելացնելու համար:',
            acceptedFiles: '.jpg, .jpeg, .png',
            responsiveImages: true,
          },
        ]}
      />
    );
}
