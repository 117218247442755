import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loading';
import { useLang } from '../../helpers/language';

export default function ContactType() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.contactType;
  const { lang } = useLang();

  const [index, setIndex] = useState('');

  const [nameEn, setNameEn] = useState('');
  const [nameRu, setNameRu] = useState('');
  const [nameHy, setNameHy] = useState('');

  const [href, setHref] = useState('');

  const modelSendToServer = {
    index,
    name: {
      en: nameEn,
      ru: nameRu,
      hy: nameHy,
    },
    href,
  };

  useEffect(() => {
    (async () => {
      if (id !== 'add') {
        let currentItem = await API.get(route, {
          _id: id,
        });
        setIndex(currentItem[0]?.index);
        setNameEn(currentItem[0]?.name?.en);
        setNameRu(currentItem[0]?.name?.ru);
        setNameHy(currentItem[0]?.name?.hy);
        setHref(currentItem[0]?.href);
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: nameHy,
            setter: setNameHy,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: 'hy',
          },
          {
            value: nameRu,
            setter: setNameRu,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: 'ru',
          },
          {
            value: nameEn,
            setter: setNameEn,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: 'en',
          },
          {
            value: href,
            setter: setHref,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.href[lang],
            placeholder: config.translate.href[lang],
            acceptedFiles: '.jpg, .jpeg, .png',
            thumbSizeWidth: 30,
            responsiveImages: true,
          },
        ]}
      />
    );
}
