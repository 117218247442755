import React from 'react';
import { ListPage } from '../ListPage';
import config from '../../config';
import { useLang } from '../../helpers/language';

export default function Orders() {
  const { lang } = useLang();
  let customSort = 'index';
  const columns = [
    {
      dataField: 'ID',
      text: 'ID',
      filter: true,
      sort: true,
      headerStyle: (column, colIndex) => {
        return {
          width: '110px',
        };
      },
      align: 'center',
    },
    {
      dataField: 'totalPrice',
      text: config.translate.price[lang],
      filter: true,
      sort: true,
      formatLive: true,
    },
    {
      dataField: 'status',
      text: config.translate.status[lang],
      sort: true,
      formatStatus: true,
    },
    {
      dataField: 'client_id',
      text: config.translate.client[lang],
      filter: true,
      formatClientId: true,
    },
    {
      dataField: 'city',
      text: config.translate.city[lang],
      formatCity: true,
    },
    {
      filter: true,
      dataField: 'payment',
      text: config.translate.paymentMethod[lang],
      formatPayment: true,
    },
    {
      dataField: 'address',
      text: config.translate.address[lang],
      filter: true,
      formatAddress: true,
    },
  ];
  function rowClassNameFormat(row, rowIdx) {
    if (row.paid.status === 2) {
      return 'bg-success';
    }
    if (row.paid.status === 0) {
      return 'bg-danger';
    }
    return '';
  }

  return (
    <ListPage
      customSort={customSort}
      columns={columns}
      route={config.api.order}
      disableAddButton={true}
      hideAddButton={true}
      // query={{
      //   ['paid.status']: { $gt: 0 },
      // }}
      defaultSortedDataField={'ID'}
      populate={['client_id']}
      rowClasses={rowClassNameFormat}
    />
  );
}
