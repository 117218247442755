import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loading';
import getUploads from '../../helpers/getUploads';
import { useLang } from '../../helpers/language';
import { useConnectedUser } from '../../helpers/connectedUserContext';
import { RealTimePrice } from '../../helpers/realTimePrice';

export default function Order() {
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;
  const route = config.api.order;
  const { lang } = useLang();
  const { notifications } = useConnectedUser();

  const [products, setProducts] = useState(new Date());

  const [ID, setID] = useState(undefined);
  const [date, setDate] = useState(new Date());

  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentSystem, setPaymentSystem] = useState();

  const [deliveryMethod, setDeliveryMethod] = useState('false');
  const [deliveryAddress, setDeliveryAddress] = useState('');

  const [desc, setDesc] = useState('');
  const [cart, setCart] = useState([]);
  const [price, setPrice] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [fishcoinForSpend, setFishcoinForSpend] = useState(0);
  const [cookPriceTotal, setCookPriceTotal] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(1000);

  const [paidFishcoin, setPaidFishcoin] = useState('');
  const [paidRegular, setPaidRegular] = useState('');
  const [paidStatus, setPaidStatus] = useState(false);

  const [clientId, setClientId] = useState('');
  const [city, setCity] = useState('');

  const [clients, setClients] = useState('');
  const [ClientGroup, setClientGroup] = useState('');

  const [history, setHistory] = useState([{}]);
  const [status, setStatus] = useState(false);
  const [Status, setSTATUS] = useState(false);
  const [idram, setIdram] = useState({});
  const [telCell, setTelCell] = useState({});

  // eslint-disable-next-line
  const [image, setImage] = useState(undefined);

  useEffect(() => {
    if (deliveryMethod) {
      if (city === config.ARMENIAN_CITIES[0]._id) {
        if (price >= 5000) {
          setDeliveryPrice(500);
        } else {
          setDeliveryPrice(1000);
        }
      } else {
        config.ARMENIAN_CITIES.forEach((item) => {
          if (city === item._id) {
            setDeliveryPrice(item.price);
          }
        });
      }
    } else {
      setDeliveryPrice(0);
    }
  }, [city, deliveryMethod, price]);

  const paymentMethods = [
    {
      _id: '1',
      name: {
        en: '',
        ru: '',
        hy: 'Առցանց',
      },
    },
    {
      _id: '2',
      name: {
        en: '',
        ru: '',
        hy: 'Առաքիչին',
      },
    },
  ];
  const paymentSystems = [
    {
      _id: '1',
      name: {
        en: 'ARCA',
        ru: '',
        hy: 'Առքա',
      },
    },
    {
      _id: '2',
      name: {
        en: 'TelCell',
        ru: '',
        hy: 'Թելսել',
      },
    },
    {
      _id: '3',
      name: {
        en: 'IDram',
        ru: '',
        hy: 'Իդրամ',
      },
    },
  ];
  const paidStatuses = [
    {
      _id: '1',
      name: { en: 'Unpaid', ru: 'Оплаченный', hy: 'Չվճարված' },
    },
    {
      _id: '2',
      name: { en: 'Paid', ru: 'Оплаченный', hy: 'Վճարված' },
    },
  ];
  const statuses = config.statuses;

  const modelSendToServer = {
    ID: ID,
    date,
    cart,
    payment: {
      method: paymentMethod ? paymentMethod : '',
      system: paymentSystem ? paymentSystem : '',
    },
    delivery: {
      method: deliveryMethod ? deliveryMethod : '',
      address: deliveryAddress ? deliveryAddress : '',
      city: city ? city : '',
      price: deliveryPrice ? deliveryPrice : 0,
    },
    desc,
    price,
    totalPrice,
    cookPriceTotal,
    fishcoinForSpend,
    paid: {
      fishcoin: paidFishcoin ? paidFishcoin : '',
      regular: paidRegular ? paidRegular : '',
      status: +paidStatus,
    },
    client_id: clientId ? clientId : undefined,
    history: history ? history : [],
    status,
    idram,
    telcell: telCell,
  };

  const cartColumns = [
    {
      name: config.translate.name[lang],
      selector: (row) => (<div className='cart-tooltip' data-toggle="tooltip" data-placement="top" title={row.name?.hy}>{row.name?.hy}</div>),
    },
    {
      name: config.translate.quantity[lang],
      selector: (row) => row.quantity,
    },
    {
      name: config.translate.price[lang],
      selector: (row) => {
        return RealTimePrice(row, ClientGroup);
      },
    },
    {
      name: config.translate.totlaPrice[lang],
      selector: (row) => {
        return RealTimePrice(row, ClientGroup) * row.quantity;
      },
    },
    {
      name: config.translate.needCook[lang],
      selector: (row) => (row.cookPrice ? 'Այո' : 'Ոչ'),
    },
    // other prices
  ];

  const invoiceColumns = [
    {
      name: config.translate.changedDate[lang],
      selector: (row) => row.date?.slice(0, -5).replace('T', ' '),
    },
    {
      name: config.translate.status[lang],
      selector: (row) => row.status?.name && row.status?.name[lang],
    },
    {
      name: config.translate.user[lang],
      selector: (row) => {
        if (row.user) {
          return row.user?.firstName && row.user?.firstName[lang]
        }
        return 'Չգրանցված հաճախորդ'
      }
    },
  ];

  useEffect(() => {
    (async () => {
      if (id !== 'add') {
        let currentItem = await API.get(route, {
          _id: id,
        });

        setID(currentItem[0]?.ID);
        setDate(currentItem[0]?.date);
        setPaymentMethod(currentItem[0]?.payment?.method);
        setPaymentSystem(currentItem[0]?.payment?.system);
        setDeliveryMethod(currentItem[0]?.delivery?.method);
        setDeliveryAddress(currentItem[0]?.delivery?.address);
        setDesc(currentItem[0]?.desc);
        setCart(currentItem[0]?.cart);
        setPrice(currentItem[0]?.price);
        setTotalPrice(currentItem[0]?.totalPrice);
        setFishcoinForSpend(currentItem[0]?.fishcoinForSpend);
        setCookPriceTotal(currentItem[0]?.cookPriceTotal);
        setHistory(currentItem[0]?.history);
        setPaidFishcoin(currentItem[0]?.paid?.fishcoin);
        setPaidRegular(currentItem[0]?.paid?.regular);
        setPaidStatus(currentItem[0]?.paid?.status);
        setStatus(currentItem[0]?.status);
        setCity(currentItem[0]?.delivery?.city);
        setIdram(currentItem[0]?.idram);
        setTelCell(currentItem[0]?.telcell);

        let images = await getUploads(id, 'image');
        setImage(images && images[0]);

        clients &&
          clients.forEach((client) => {
            if (client._id === currentItem[0]?.client_id) {
              setClientId(client._id);
            }
          });

        statuses &&
          statuses.forEach((status) => {
            if (status._id === currentItem[0]?.status) {
              setSTATUS(status);
            }
          });
      } else {
        let latestOne = await API.get(route, {}, { ID: -1 }, {});
        if (latestOne[0] && latestOne[0].ID) {
          setID(latestOne[0].ID + 1);
        } else {
          setID(1);
        }
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy, id]);

  useEffect(() => {
    if (clientId) {
      config.CLIENT_GROUPS.forEach((item) => {
        clients &&
          clients.forEach((client) => {
            if (client._id === clientId) {
              if (client.client_group_id === item?._id) {
                setClientGroup(item);
              }
            }
          });
      });
    }
    // eslint-disable-next-line
  }, [clientId]);

  useEffect(() => {
    (async () => {
      let rawClient = await API.get(config.api.client);
      if (rawClient) setClients(rawClient);

      let rawProducts = await API.get(config.api.product);
      if (rawProducts) setProducts(rawProducts);

      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  useEffect(() => {
    statuses &&
      statuses.forEach((st) => {
        if (st._id === status) {
          setSTATUS(st);
        }
      });
    // eslint-disable-next-line
  }, [status]);

  useEffect(() => {
    (async () => {
      let localPrice = 0;

      if (typeof cart === 'object') {
        cart?.forEach((item) => {
          let quantity = item.quantity || 0;
          localPrice += RealTimePrice(item, ClientGroup) * quantity;
        });
      }

      setPrice(localPrice);
    })();
  }, [ClientGroup, cart]);

  useEffect(() => {
    let localPrice = 0;
    if (deliveryMethod !== 'false') {
      localPrice += deliveryPrice;
    }
    if (cookPriceTotal > 0) {
      localPrice += cookPriceTotal;
    }
    if (fishcoinForSpend > 0) {
      localPrice -= fishcoinForSpend;
    }
    setTotalPrice(localPrice + price);
  }, [cookPriceTotal, deliveryMethod, deliveryPrice, price, fishcoinForSpend]);

  useEffect(() => { }, [notifications]);
  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        History={history}
        Status={Status}
        userRoles={['admin', 'manager']}
        id={id}
        modelSendToServer={modelSendToServer}
        inputs={[
          id !== 'add' && {
            value: ID,
            type: 'text',
            usageType: 'textfield',
            label: 'ID',
            disabled: true,
          },
          id !== 'add' && {
            value: date,
            setter: setDate,
            type: 'date',
            disabled: true,
            usageType: 'datePicker',
            label: config.translate.startDate[lang],
            placeholder: 'Select date',
          },
          {
            value: price,
            setter: setPrice,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.orderPrice[lang],
            placeholder: config.translate.orderPrice[lang],
            disabled: true,
          },
          {
            value: fishcoinForSpend,
            setter: setFishcoinForSpend,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.fishcoinForSpend[lang],
            placeholder: config.translate.fishcoinForSpend[lang],
            disabled: true,
          },
          {
            value: cookPriceTotal,
            setter: setCookPriceTotal,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.cookPriceTotal[lang],
            placeholder: config.translate.cookPriceTotal[lang],
            disabled: true,
          },
          {
            value: paymentMethod,
            setter: setPaymentMethod,
            optionListValue: paymentMethods,
            type: 'optionlist',
            usageType: 'optionlist',
            label: config.translate.paymentMethod[lang],
          },
          paymentMethod === '1' && {
            value: paymentSystem,
            setter: setPaymentSystem,
            optionListValue: paymentSystems,
            type: 'optionlist',
            usageType: 'optionlist',
            label: config.translate.paymentSystem[lang],
          },
          {
            value: String(paidStatus),
            setter: setPaidStatus,
            optionListValue: paidStatuses,
            type: 'optionlist',
            usageType: 'optionlist',
            label: config.translate.paidStatus[lang],
          },
          {
            value: clientId,
            setter: setClientId,
            optionListValue: clients,
            field: 'username',
            type: 'optionlist',
            usageType: 'optionlist',
            label: config.translate.client[lang],
          },

          // {
          //   value: deliveryAddress,
          //   setter: setDeliveryAddress,
          //   optionListValue: deliveryAddress,
          //   type: 'optionlist',
          //   usageType: 'optionlist',
          //   label: config.translate.deliveryAddress[lang],
          // },

          // {
          //   value: paidFishcoin,
          //   setter: setPaidFishcoin,
          //   type: 'number',
          //   usageType: 'number',
          //   label: 'Վճարված Fishcoin-ով',
          //   placeholder: 'Input in latin',
          // },
          // {
          //   value: paidRegular,
          //   setter: setPaidRegular,
          //   type: 'number',
          //   usageType: 'number',
          //   label: 'Paid regular',
          //   placeholder: 'Input in latin',
          // },

          {
            value: status,
            setter: setStatus,
            optionListValue: statuses,
            type: 'optionlist',
            usageType: 'optionlist',
            label: config.translate.status[lang],
          },

          {
            value: deliveryMethod,
            setter: setDeliveryMethod,
            radioValue: [
              { value: 'true', name: 'Առաքում' },
              { value: 'false', name: 'խանութից' },
            ],
            type: 'radio',
            usageType: 'radio',
            label: config.translate.deliveryMethod[lang],
          },
          deliveryMethod !== 'false'
            ? {
              value: deliveryAddress,
              setter: setDeliveryAddress,
              type: 'text',
              usageType: 'textfield',
              label: config.translate.address[lang],
              placeholder: config.translate.address[lang],
            }
            : null,
          deliveryMethod !== 'false'
            ? {
              value: city,
              setter: setCity,
              optionListValue: config.ARMENIAN_CITIES,
              type: 'optionlist',
              usageType: 'optionlist',
              label: config.translate.city[lang],
              field: 'lang',
            }
            : null,
          deliveryMethod !== 'false'
            ? {
              value: deliveryPrice,
              setter: setDeliveryPrice,
              type: 'text',
              usageType: 'textfield',
              label: config.translate.deliveryPrice[lang],
              placeholder: config.translate.deliveryPrice[lang],
              disabled: true,
            }
            : null,
          {
            value: totalPrice,
            setter: setTotalPrice,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.orderTotalPrice[lang],
            placeholder: config.translate.orderTotalPrice[lang],
            disabled: true,
          },
          {
            value: desc,
            setter: setDesc,
            type: 'text',
            usageType: 'textarea',
            label: config.translate.desc[lang],
            placeholder: config.translate.desc[lang],
            fullWidth: true,
          },
        ]}
        cartComponent={[
          {
            title: 'Ապրանքներ',
            data: cart,
            value: products,
            columns: cartColumns,
            setter: setCart,
            width: '220px',
            // setPrice: setPrice,
            clinet: clientId,
            deliveryAddress,
            deliveryMethod,
            // customStyles: true,
          },
        ]}
        tableComponent={[
          {
            title: config.translate.historyTable[lang],
            data: history,
            columns: invoiceColumns,
          },
        ]}
      />
    );
}
