import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../helpers/auth';
import config from '../config';

export default function Login() {
  let [username, setUsername] = useState('');
  let [password, setPassword] = useState('');
  const { loggedIn, login } = useAuth();
  const [open, setOpen] = useState(false);
  const lang = 'hy';

  const toggleAlert = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };
  function handleUsernameChange(event) {
    setUsername(event.target.value);
  }
  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }
  async function handleSubmit(event) {
    event.preventDefault();
    await login(username, password);
    // if (loggedIn === false) setTimeout(() => toggleAlert(), 0);
    setTimeout(() => toggleAlert(), 1000);
  }

  if (loggedIn) {
    return <Redirect to='/' />;
  }

  return (
    <>
      <div className={`alert ${open ? 'show' : ''}`}>
        <div className='toast bg-danger fade show'>
          <div className='toast-body'>
            {config.translate.incorrectLogin[lang]}
          </div>
        </div>
      </div>

      <div className='login-box'>
        <div className='login-logo'>
          <img src='/blue_logo.svg' alt='main page logo' />
          <br />
          {config.translate.supportText[lang]}{' '}
          <a href='mainlto:support@icefish.am'>support@icefish.am</a>
        </div>
        <div className='card'>
          <div className='card-body login-card-body'>
            <p className='login-box-msg'>{config.translate.login[lang]}</p>
            <form onSubmit={handleSubmit} method='post'>
              <div className='input-group mb-3'>
                <input
                  type='username'
                  className='form-control'
                  placeholder={config.translate.username[lang]}
                  value={username}
                  onChange={handleUsernameChange}
                />
                <div className='input-group-append'>
                  <div className='input-group-text'>
                    <span className='fas fa-envelope'></span>
                  </div>
                </div>
              </div>
              <div className='input-group mb-3'>
                <input
                  type='password'
                  className='form-control'
                  placeholder={config.translate.password[lang]}
                  autoComplete='on'
                  value={password}
                  onChange={handlePasswordChange}
                />
                <div className='input-group-append'>
                  <div className='input-group-text'>
                    <span className='fas fa-lock'></span>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-4'>
                  <button type='submit' className='btn btn-primary btn-block'>
                    {config.translate.loginButton[lang]}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <br />
        {config.translate.developed[lang]}{' '}
        <a href='htpps://www.deepmindsystems.com'>www.deepmindsystems.com</a>
        -ում
      </div>
    </>
  );
}
