import React from 'react';
import { ListPage } from '../ListPage';
import config from '../../config';
import { useLang } from '../../helpers/language';

export default function Categories() {
  let customSort = 'index';
  const { lang } = useLang();
  const columns = [
    {
      dataField: 'index',
      text: config.translate.index[lang],
      filter: true,
      sort: true,
      headerStyle: (column, colIndex) => {
        return {
          width: '110px',
        };
      },
      align: 'center',
    },
    {
      dataField: 'name.hy',
      text: config.translate.name[lang],
      filter: true,
      sort: true,
      formatLive: true,
    },
    {
      dataField: 'name.ru',
      text: config.translate.name[lang] + '-Ru',
      filter: true,
      sort: true,
      formatLive: true,
    },
    {
      dataField: 'name.en',
      text: config.translate.name[lang] + '-En',
      filter: true,
      sort: true,
      formatLive: true,
    },
  ];
  return (
    <ListPage
      customSort={customSort}
      columns={columns}
      route={config.api.category}
      defaultSortedDataField={'index'}
    />
  );
}
